<template>
    <section class="page-wrap">
        <div class="inner-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>{{ page_title }}</h1>
                        <p>We've more than {{ total_properties }} properties</p>
                    </div>
                    <div class="col-12">
                        <Breadcrumb />
                    </div>
                </div>
            </div>        
        </div>
        <PropertyList @totla-properties="handleTotalProperties($event)" />
    </section>
</template>
<script>
import PropertyList from './partials/PropertyList'
import Breadcrumb from '@/components/Breadcrumb'
export default {
    name: "PropertyListing",
    components:{
        PropertyList,
        Breadcrumb,
    },
    data() {
        return {
            page_title:"List of Properties",
            total_properties:"",
        }
    },
    methods: {
        handleTotalProperties($event){
            let app = this;
            app.total_properties = $event;
        }
    }
}
</script>
<style src="../../assets/css/property.css"></style>